var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"mkt-options"},[_c('a-form-item',[_c('a-checkbox-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'marketing_types',
          {
            rules: [
              {
                required: false,
                message: 'Obrigatório',
              },
            ],
          },
        ]),expression:"[\n          'marketing_types',\n          {\n            rules: [\n              {\n                required: false,\n                message: 'Obrigatório',\n              },\n            ],\n          },\n        ]"}],on:{"change":_vm.onMarketingTypesChange}},_vm._l((_vm.marketingTypesItens),function(item,index){return _c('a-checkbox',{key:index,staticClass:"mr-20",attrs:{"value":item.value,"disabled":item.disabled}},[_vm._v("\n          "+_vm._s(item.label)+"\n        ")])}),1)],1)],1),_vm._v(" "),(_vm.marketingTypes.length)?_c('div',{staticClass:"mkt-tabs"},[_c('div',{staticClass:"tabs"},[_c('a-row',{attrs:{"gutter":10}},_vm._l((_vm.marketingTypesItens),function(item,index){return _c('span',{key:index},[(_vm.marketingTypes.includes(item.label))?_c('a-col',{attrs:{"span":6},on:{"click":function($event){return _vm.onClickActiveTab(item.label)}}},[_c('div',{staticClass:"tab",class:_vm.activeTab == item.label ? 'active' : ''},[_c('div',[_vm._v(_vm._s(item.label))])])]):_vm._e()],1)}),0)],1)]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"mkt-tabs-content"},[(_vm.marketingTypes.includes('Livelo'))?_c('LiveloTab',{attrs:{"total":_vm.total,"form":_vm.form,"contract":_vm.contract,"tempContract":_vm.tempContract,"showSubtractProfit":true}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }
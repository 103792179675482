<template>
  <div>
    <div class="mkt-options">
      <a-form-item>
        <a-checkbox-group
          v-decorator="[
            'marketing_types',
            {
              rules: [
                {
                  required: false,
                  message: 'Obrigatório',
                },
              ],
            },
          ]"
          @change="onMarketingTypesChange"
        >
          <a-checkbox
            v-for="(item, index) in marketingTypesItens"
            class="mr-20"
            :key="index"
            :value="item.value"
            :disabled="item.disabled"
          >
            {{ item.label }}
          </a-checkbox>
        </a-checkbox-group>
      </a-form-item>
    </div>

    <div v-if="marketingTypes.length" class="mkt-tabs">
      <div class="tabs">
        <a-row :gutter="10">
          <span v-for="(item, index) in marketingTypesItens" :key="index">
            <a-col
              v-if="marketingTypes.includes(item.label)"
              @click="onClickActiveTab(item.label)"
              :span="6"
            >
              <div class="tab" :class="activeTab == item.label ? 'active' : ''">
                <div>{{ item.label }}</div>
              </div>
            </a-col>
          </span>
        </a-row>
      </div>
    </div>

    <div class="mkt-tabs-content">
      <LiveloTab
        v-if="marketingTypes.includes('Livelo')"
        :total="total"
        :form="form"
        :contract="contract"
        :tempContract="tempContract"
        :showSubtractProfit="true"
      />
    </div>
  </div>
</template>

<script>
import LiveloTab from "../tabs/LiveloTab.vue";

export default {
  name: "ContractMarketingModal",
  props: {
    total: Number,
    contract: Object,
    tempContract: Object,
    form: Object,
  },
  components: { LiveloTab },
  data() {
    return {
      activeTab: 0,
      marketingTypes: [],
      marketingTypesItens: [
        {
          label: "Livelo",
          value: "Livelo",
          disabled: false,
        },
        {
          label: "E-mail",
          value: "E-mail",
          disabled: true,
        },
        {
          label: "SMS",
          value: "SMS",
          disabled: true,
        },
      ],
    };
  },
  mounted() {
    this.marketingTypes = this.tempContract.marketing_types
      ? JSON.parse(this.tempContract.marketing_types)
      : [];

    this.form.setFieldsValue({
      marketing_types: this.tempContract.marketing_types
        ? JSON.parse(this.tempContract.marketing_types)
        : [],
    });

    if (this.marketingTypes.includes("Livelo")) {
      this.activeTab = "Livelo";
    }
  },
  methods: {
    onMarketingTypesChange(val) {
      this.activeTab = val;
      setTimeout(() => {
        this.marketingTypes = this.tempContract.marketing_types
          ? JSON.parse(this.tempContract.marketing_types)
          : [];
      }, 100);
    },
    onClickActiveTab(item) {
      item;
    },
  },
};
</script>

<style lang="sass">
.mkt-options
  .ant-checkbox-wrapper-disabled
    opacity: 0.5 !important
  .ant-checkbox-disabled + span
    color: #fff !important
    opacity: 0.5 !important
</style>

<style lang="sass" scoped>
.mkt-tabs
  border: 0
  border-bottom: 1px solid #ddd
  background: #eee
  margin: -20px -24px 30px -24px
  padding: 15px 15px 15px
  .tab
    cursor: pointer
    text-align: center
    color: #999
    font-size: 13px
    padding: 10px
    border: 1px solid #ddd
    background: #ffffff
    font-weight: 400
    border-top: 2px solid #ccc
    transition: 0.3s
    margin-bottom: -1px
    border-radius: 6px
  .tab.active
    border-top: 2px solid #be4178
    font-weight: 600
    color: #be4178
    background: #ffffff
.mkt-options
  background: #444
  color: #ffffff
  height: 60px
  padding: 10px 20px
  margin: -24px -24px 20px -24px
  label
    color: #ffffff
    font-size: 13px
  .ant-checkbox-wrapper
    margin-right: 8px !important
    margin-top: 10px !important
    margin-left: 0 !important
</style>
